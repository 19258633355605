import { DisplayAddress, isDisplayAddress, validateDisplayAddress } from "./address";
import { DisplayDepartment, isDisplayDepartment, validateDisplayDepartment } from "./department";
import { DisplayPhone, isDisplayPhone, validateDisplayPhone } from "./phone";
import { DisplayTag, isDisplayTag, validateDisplayTag } from "./tag";
import { isBool, isString } from "./type_guards";
import { validateEmailString, validateNonEmptyString } from "./validators";

export interface DisplayContact {
    id: string
    firstName: string
    lastName: string
    email: string
    position: string
    department?: DisplayDepartment
    noMarketing: boolean
    twitter?: string
    facebook?: string
    linkedin?: string
    skype?: string
    address?: DisplayAddress
    tags: readonly DisplayTag[]
    phones: readonly DisplayPhone[]
    hubspotSyncStatus?: string
    active: boolean
}

// Type guards
export const isDisplayContact = (contact: unknown): contact is DisplayContact => {
    if (
        typeof contact !== 'object' ||
        !('id' in contact) ||
        !('firstName' in contact) ||
        !('lastName' in contact) ||
        !('email' in contact) ||
        !('position' in contact) ||
        !('department' in contact) ||
        !('noMarketing' in contact) ||
        !('tags' in contact) ||
        !('phones' in contact)
    ) {
        return false;
    }

    return (
        isString(contact.id) &&
        isString(contact.firstName) &&
        isString(contact.lastName) &&
        isString(contact.email) &&
        isString(contact.position) &&
        (contact.department === undefined || isDisplayDepartment(contact.department)) &&
        isBool(contact.noMarketing) &&
        (!('twitter' in contact) || contact.twitter === undefined || isString(contact.twitter)) &&
        (!('facebook' in contact) || contact.facebook === undefined || isString(contact.facebook)) &&
        (!('linkedin' in contact) || contact.linkedin === undefined || isString(contact.linkedin)) &&
        (!('skype' in contact) || contact.skype === undefined || isString(contact.skype)) &&
        (!('address' in contact) || contact.address === undefined || isDisplayAddress(contact.address)) &&
        (Array.isArray(contact.tags) && contact.tags.every(isDisplayTag)) &&
        (Array.isArray(contact.phones) && contact.phones.every(isDisplayPhone)) &&
        (!('hubspotSyncStatus' in contact) || contact.hubspotSyncStatus === undefined || isString(contact.hubspotSyncStatus))
    );
}

// Validators
export const validateDisplayContact = (contact: DisplayContact) => {
    validateNonEmptyString(contact.id);
    if (contact.department) {
        validateDisplayDepartment(contact.department);
    }
    if (contact.address) {
        validateDisplayAddress(contact.address);
    }
    contact.tags.forEach(validateDisplayTag);
    contact.phones.forEach(validateDisplayPhone);
}
