import { DisplayAddress } from "../../../types";
import React from "react";
import { colors, Text } from "@commonsku/styles";
import styled from "styled-components";
import { toTitleCase } from "../../../utils";

//Styles Begin
const AddressCard = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 230px;
    height: fit-content;
    cursor: pointer;
    padding: 16px;
    gap: 10px;
    background: ${colors.primary1[10]};
  }
`;

const AddressText = styled(Text)<{ $bold?: boolean }>`
  text-wrap: wrap;
  font-size: ${(props) => (props.$bold ? "16px" : "14px")};
  font-weight: ${(props) => (props.$bold ? "700" : "400")};
  color: ${colors.primary1.main};
`;
//Styles End

export interface AddressSummaryProps {
  address: DisplayAddress;
  onClick: (address: DisplayAddress) => void;
}

export const AddressSummary = ({ address, onClick }: AddressSummaryProps) => {
  return (
    <AddressCard
      data-testid={"Address-card-" + address.id}
      onClick={() => onClick(address)}
    >
      <AddressText $bold>
        {address.name ? address.name : "Address"}
      </AddressText>
      <AddressText>{address.address_line_1}</AddressText>
      {address.postal && (
        <AddressText>{address.type === 'BOTH' ? '' : toTitleCase(address.type) + ' '}Zip Code: {address.postal}</AddressText>
      )}
      <AddressText>
        {[address.city, address.state, address.country]
          .filter((x) => x)
          .join(", ")}
      </AddressText>
    </AddressCard>
  );
};
