import { isString, isURL } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayUser {
    id: string
    firstName?: string
    lastName?: string
    avatarImagePath?: URL 
}

// Type guards
export const isDisplayUser = (user: unknown): user is DisplayUser => {
    if (
        typeof user !== 'object' ||
        !('id' in user)
    ) {
        return false;
    }

    return (
        isString(user.id) &&
        (!('firstName' in user) || user.firstName === undefined || isString(user.firstName)) &&
        (!('lastName' in user) || user.lastName === undefined || isString(user.lastName)) &&
        (!('avatarImagePath' in user) || user.avatarImagePath === undefined || isURL(user.avatarImagePath))
    );
}

// Validators
export const validateDisplayUser = (user: DisplayUser) => {
    validateNonEmptyString(user.id);
}
