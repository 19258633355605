import { DisplayContact } from "../../../types";
import React from "react";
import { MarketingStatusIcon, colors, Text } from "@commonsku/styles";
import styled from "styled-components";

interface ContactSummaryProps {
  contact: DisplayContact;
  onClick?: (contact: DisplayContact) => void;
  isFullContact?: boolean;
  cardColor?: string;
  textColor?: string;
}

//Styles Begin
const ContactCard = styled.div<{ $cardColor: string }>`
  &&& {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 230px;
    min-width: 150px;
    height: fit-content;
    cursor: pointer;
    padding: 16px;
    gap: 10px;
    background-color: ${(props) => props.$cardColor};
  }
`;

const ContactText = styled(Text)<{ $bold?: boolean, $textColor: string }>`
  align-self: left;
  line-height: 24px;
  font-size: ${(props) => (props.$bold ? "16px" : "14px")};
  font-weight: ${(props) => (props.$bold ? "700" : "400")};
  color: ${(props) => props.$textColor};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconRow = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;
//Styles End

export const ContactSummary = ({
  contact,
  onClick,
  isFullContact = true,
  cardColor = colors.primary1[10],
  textColor = colors.primary1.main,
}: ContactSummaryProps) => {
  return (
    <ContactCard
      onClick={onClick ? () => onClick(contact) : null}
      data-testid={"contact-card-" + contact.id}
      $cardColor={cardColor}
    >
      <ContactText as="div" $textColor={textColor} $bold>
        {contact.firstName && contact.lastName
          ? `${contact.firstName} ${contact.lastName}`
          : "Contact"}
      </ContactText>

      {isFullContact && (
        <ContactText as="div" $textColor={colors.neutrals.main}>
          {contact.position}
        </ContactText>
      )}
      <ContactText as="div" $textColor={textColor}>{contact.email}</ContactText>
      {isFullContact && (
        <div>
          {contact.phones && (
            <div>
              {contact.phones.map((phone) => (
                <ContactText as="div" key={phone.id} $textColor={colors.neutrals.main}>
                  {phone.type}: {phone.number}
                </ContactText>
              ))}
            </div>
          )}
        </div>
      )}
      {isFullContact && (
        <IconRow>
          {contact.noMarketing && <MarketingStatusIcon approved={false} />}
          {contact.hubspotSyncStatus === 'ACTIVE' && <img width={16} height={16} src="/images/icons/hubspot-logo.svg" alt="Hubspot" />}
        </IconRow>
      )}
    </ContactCard>
  );
};
