import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export type AddressType =
    | 'SHIPPING'
    | 'BILLING'
    | 'BOTH'

export interface DisplayAddress {
    id: string
    type: AddressType
    name?: string
    city: string
    state: string
    country: string
    postal: string
    address_line_1: string
    address_line_2: string
    address_line_3: string
    address_line_4: string
}

// Type guards
export const isAddressType = (value: unknown): value is AddressType => {
    return (isString(value) && ['SHIPPING', 'BILLING', 'BOTH'].includes(value));
}

export const isDisplayAddress = (address: unknown): address is DisplayAddress => {
    if (
        typeof address !== 'object' ||
        !('id' in address) ||
        !('type' in address) ||
        !('city' in address) ||
        !('state' in address) ||
        !('country' in address) ||
        !('postal' in address) ||
        !('address_line_1' in address) ||
        !('address_line_2' in address) ||
        !('address_line_3' in address) ||
        !('address_line_4' in address)
    ) {
        return false;
    }

    return (
        isString(address.id) &&
        isAddressType(address.type) &&
        (!('name' in address) || address.name === undefined || isString(address.name)) &&
        isString(address.city) &&
        isString(address.state) &&
        isString(address.country) &&
        isString(address.postal) &&
        isString(address.address_line_1) &&
        isString(address.address_line_2) &&
        isString(address.address_line_3) &&
        isString(address.address_line_4)
    );
}

// Validators
export const validateDisplayAddress = (address: DisplayAddress) => {
    validateNonEmptyString(address.id);
}
