import React, { useRef, useState } from 'react';
import { Col, Button, Popup, PopupHeader } from '@commonsku/styles';
import { EditContactForm } from '../../contact/EditContactForm';
import { DisplayContact } from '../../../types';

interface EditContactPopupProps {
    contact: DisplayContact;
    parent_id: string;
    parent_type?: string;
    onClosePopup: () => void;
    onEdit: (contact: any) => void;
}

export const EditContactPopup = ({
    contact,
    parent_id,
    parent_type='CLIENT',
    onClosePopup,
    onEdit,
}: EditContactPopupProps) => {
    const [form, setForm] = useState({});
    const [isFormValid, setFormValid] = useState(true);
    const [showErrorHighlight, setShowErrorHighlight] = useState(false);
    const ref = useRef();

    const scrollToTop = () => {
        if (ref.current) {
            (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            scrollToTop();
            return false;
        }
        onEdit(form);
        onClosePopup();
    };

    return (
        <Popup
            style={{ height: '90%', paddingBottom: '50px' }}
            onClose={() => onClosePopup()}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                    <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                        <span className="title">Edit Contact</span>
                    </Col>
                    <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                        <Button style={{ marginRight: 5 }} onClick={onClosePopup}>Close</Button>
                        <Button cta onClick={handleSubmit}>Save</Button>
                    </Col>
                </PopupHeader>
            }
        >
            <EditContactForm
                ref={ref}
                contact={contact}
                parent_id={parent_id}
                parent_type={parent_type}
                showError={showErrorHighlight}
                setFormValid={setFormValid}
                setForm={setForm}
            />
        </Popup>
    );
}
