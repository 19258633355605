import { default as CreateAddressPopup } from "../company-search/popups/CreateAddressPopup";
import { default as CreateContactPopup } from "../company-search/popups/CreateContactPopup";
import { default as NewProjectPopup } from "../project/NewProjectPopup";
import { ClientDetailsPopup } from "./popups/ClientDetailsPopup";
import DeleteClientPopup from "./popups/DeleteClientPopup";

export const popups = {
    "create-contact": CreateContactPopup,
    "create-address": CreateAddressPopup,
    "create-project": NewProjectPopup,
    "view-details": ClientDetailsPopup,
    "delete-client": DeleteClientPopup,
} as const;

export type ClientSubPage =
    | 'Overview'
    | 'Activity'
    | 'Contacts'
    | 'Addresses'
    | 'Projects'
    | 'Shops'
    | 'Library'

/**
 * An entity of type T that doesn't have a database ID (yet)
 */
export type Unvalidated<T extends { id: string }> = Omit<T, 'id' | 'messageId'>
