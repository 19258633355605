import styled from "styled-components";

export const ScrollIndicator = styled.div<{
    $direction?: "left" | "right";
    $visible?: boolean;
  }>`
    position: absolute;
    top: 0;
    height: 100%;
    width: 76px;
    display: ${(props) => (props.$visible ? "flex" : "none")};
    z-index: 100;
    pointer-events: none;
    background: ${(props) =>
      props.$direction === "left"
        ? "linear-gradient(to right, #FFFFFF, transparent)"
        : "linear-gradient(to left, #FFFFFF, transparent)"};
    ${(props) => (props.$direction === "left" ? "left: 0" : "right: 0")};
  `;
  