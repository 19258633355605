import React, { useRef, useState } from "react";
import { DisplayAddress } from "../../../types";
import { Button, Col, Popup, PopupHeader } from "@commonsku/styles";
import CreateAddressForm from "../../address/CreateAddressForm";

export interface EditAddressFields {
    parent_type: 'CLIENT',
    parent_id: string,
    address_type: DisplayAddress['type'],
    address_name: string,
    address_company: string,
    address_line_1: string,
    address_line_2: string,
    address_line_3: string,
    address_line_4: string,
    address_country: string,
    address_state: string,
    address_city: string,
    address_postal: string,   
}

export interface EditAddressPopupProps {
    clientName: string;
    address: DisplayAddress;
    parent_id: string;
    parent_type?: string;
    onClosePopup: () => void;
    onEdit: (address: any) => void;
}

export const EditAddressPopup = ({
    clientName,
    address,
    parent_id,
    parent_type='CLIENT',
    onClosePopup,
    onEdit,
}: EditAddressPopupProps) => {
    const [form, setForm] = useState({});
    const [isFormValid, setFormValid] = useState(true);
    const [showErrorHighlight, setShowErrorHighlight] = useState(false);
    const ref = useRef();

    const scrollToTop = () => {
        if (ref.current) {
            (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            scrollToTop();
            return false;
        }
        onEdit(form);
        onClosePopup();
    };

    return (
        <Popup
            style={{ height: '90%', paddingBottom: '50px' }}
            onClose={() => onClosePopup()}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                    <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                        <span className="title">Edit Address</span>
                    </Col>
                    <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                        <Button style={{ marginRight: 5 }} onClick={onClosePopup}>Close</Button>
                        <Button cta onClick={handleSubmit}>Save</Button>
                    </Col>
                </PopupHeader>
            }
        >
            <CreateAddressForm
                ref={ref}
                // @ts-ignore
                parent_id={parent_id}
                parent_type={parent_type}
                setForm={setForm}
                setFormValid={setFormValid}
                showError={showErrorHighlight}
                addressToUpdate={address}
                companyName={clientName}
            />
        </Popup>
    );
};