import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeptOptions, getOptions, getPhoneTypeOptions, PHONE_TYPE_MAP } from "../company-search";
import { addressByParentOptions } from "../../selectors/addresses";
import { useForm } from "../../hooks";
import { DisplayContact } from "../../types";
import { titleCase, validateEmail } from "../../utils";
import { Alert, LabeledSelect } from '../helpers';
import { createLoadTags, createLoadDepartmentList } from "../../actions";
import { createLoadAddressList } from "../../actions/address";
import { Col, colors, Label, LabeledCheckbox, LabeledInput, Row } from "@commonsku/styles";
import styled from "styled-components";
import { PhoneRow } from "./CreateContactForm";
import AutosuggestTagsInput from "../AutosuggestTagsInput";
import { find, map } from "lodash";

const FormRow = styled(Row)<{ showError: boolean }>`
    margin-top: ${props => props.showError ? 40 : 0}px;
`;

export interface EditContactFields {
    parent_id: string;
    parent_type: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_email: string;
    contact_position: string;
    phones: Array<{
        phone_type: string;
        phone_number: string;
        phone_extension: string;
    }>;
    contact_department_id: string;
    contact_twitter: string;
    contact_linkedin: string;
    contact_facebook: string;
    contact_skype: string;
    contact_default_address_id: string;
    contact_no_marketing: boolean;
    contact_tags: string[];
    errors: string;
}

export interface EditContactFormProps {
    contact: DisplayContact;   
    parent_id: string;
    parent_type: string;
    showError: boolean;
    setFormValid: (isFormValid: boolean) => void;
    setForm: (form: EditContactFields) => void;
    smallerScreenSize?: boolean;
}

interface Address {
    address_id: string;
    address_name: string;
    address_type: string;
    parent_id: string;
    parent_type: string;
}

export const EditContactForm = forwardRef<HTMLDivElement, EditContactFormProps>(({
    contact,
    parent_id,
    parent_type='CLIENT',
    showError=false,
    setFormValid,
    setForm,
    smallerScreenSize=false,
}, ref) => {
    const dispatch = useDispatch();
    const tagOptions = useSelector(state => getOptions(
        // @ts-ignore
        Object.values(state.entities.tags),
        'label',
        'label',
    ))
    const getAddressOptions = useCallback(
        (state) => (Object.values(state?.entities?.addresses ?? {}) as Array<Address>).filter(
            a => a.parent_type === parent_type && a.parent_id === parent_id
        ).map(
            a => ({
                label: `${a.address_name}`.trim(),
                value: a.address_id,
                type: a.address_type
            })
        ),
        [parent_id, parent_type]
    );
    const departmentOptions = useSelector(state => getDeptOptions(state, { parent_id, parent_type }));
    const addressOptions = useSelector(getAddressOptions);
    const phone_types = getPhoneTypeOptions();
        
    const { form, setInForm, handleChange, handleSelectChange } = useForm({
        parent_id,
        parent_type,
        contact_first_name: contact.firstName,
        contact_last_name: contact.lastName,
        contact_email: contact.email,
        contact_position: contact.position,
        phones: contact.phones.map(phone => {
            const phoneParts = phone.number.split(' ext. ');
            return {
                phone_type: titleCase(phone.type),
                phone_number: phoneParts[0],
                phone_extension: phoneParts[1] ?? '',
            };
        }),
        contact_department_id: contact.department?.id ?? '',
        contact_twitter: contact.twitter ?? '',
        contact_facebook: contact.facebook ?? '',
        contact_linkedin: contact.linkedin ?? '',
        contact_skype: contact.skype ?? '',
        contact_default_address_id: contact.address?.id,
        contact_no_marketing: contact.noMarketing ? 1 : 0,
        contact_tags: contact.tags.map(tag => tag.label),
        errors: '',
    });
    
    const isFormValid = useMemo(() => {
        return form.contact_first_name.length > 0
            && form.contact_last_name.length > 0
            && form.contact_email.length > 0
            && form.contact_position.length > 0
            && form.contact_department_id.length > 0
            && (form.contact_email && validateEmail(form.contact_email));
    }, [form]);

    const addPhone = useCallback(
        (phone_type=PHONE_TYPE_MAP.WORK, phone='', ext='') => {
            setInForm(
                'phones', [...form.phones, {phone_type, phone_number: phone, phone_extension: ext}]
            );
        },
        [form, setInForm]
    );

    const removePhone = useCallback((index) => {
        if (form.phones.length > 1) {
            setInForm(
                'phones', form.phones.filter((v, i) => i !== index)
            );
        }
    }, [form, setInForm]);

    const updatePhone = useCallback((index, name, value) => {
        setInForm(
            'phones', [
                ...form.phones.slice(0, index),
                {...form.phones[index], [name]: value},
                ...form.phones.slice(index+1),
            ]
        );
    }, [form, setInForm]);

    const renderAlert = () => {
        if (showError && !isFormValid) {
            return <Alert type="danger">Please complete all required fields!</Alert>;
        }
        if (showError && isFormValid) {
            <Alert type="success">Looks good!</Alert>;
        }
        return null;
    };

    useEffect(() => {
        dispatch(createLoadTags());
        dispatch(createLoadAddressList(parent_id, parent_type));
        dispatch(createLoadDepartmentList(parent_type));
        addPhone();
    }, []);

    useEffect(() => {
        setFormValid && setFormValid(isFormValid);
    }, [isFormValid, setFormValid]);

    useEffect(() => {
        setForm && setForm({
            ...form,
            contact_first_name: `${form.contact_first_name}`.trim(),
            contact_last_name: `${form.contact_last_name}`.trim(),
            contact_position: `${form.contact_position}`.trim(),
            contact_email: `${form.contact_email}`.trim(),
            contact_twitter: `${form.contact_twitter}`.trim(),
            contact_linkedin: `${form.contact_linkedin}`.trim(),
            contact_facebook: `${form.contact_facebook}`.trim(),
            contact_skype: `${form.contact_skype}`.trim(),
            phones: form.phones.map(p => ({
                ...p,
                phone_number: `${p.phone_number}`.trim(),
                phone_extension: `${p.phone_extension}`.trim(),
            })),
        });
    }, [form, setForm]);


    return (
        <div ref={ref}>
            <FormRow start showError>
                {renderAlert()}
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='First Name'
                        name='contact_first_name'
                        value={form.contact_first_name}
                        error={showError && form.contact_first_name === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_first_name', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='Last Name'
                        name='contact_last_name'
                        value={form.contact_last_name}
                        error={showError && form.contact_last_name === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_last_name', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        label='Position'
                        name='contact_position'
                        value={form.contact_position}
                        error={showError && form.contact_position === ''}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_position', value);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput required
                        type='email'
                        label='Email'
                        name='contact_email'
                        value={form.contact_email}
                        error={showError && (form.contact_email === '' || !validateEmail(form.contact_email))}
                        onChange={e => {
                            const value = `${e.target.value || ''}`.trimStart();
                            if (value.length > 250) { return; }
                            setInForm('contact_email', value);
                        }}
                    />
                </Col>
            </FormRow>
            {form.phones && form.phones.map((v, i) => {
                return (
                    <PhoneRow key={i}
                        index={i}
                        onUpdate={(name, value) => updatePhone(i, name, value)}
                        phone_types={phone_types}
                        phone_extension={v.phone_extension}
                        phone_type={v.phone_type}
                        phone_number={v.phone_number}
                        removePhone={form.phones.length >= 2 ? removePhone : null}
                    />
                );
            })}
            <Row start>
                <Col padded>
                    <a style={{color: colors.primary}} href="#" onClick={() => addPhone()}>Add Phone</a>
                </Col>
            </Row>
            <Row>
                <Col xs md={smallerScreenSize ? 6 : 3} padded
                    style={smallerScreenSize ? {paddingBottom: 0} : {}}
                >
                    <LabeledSelect required
                        label='Department'
                        name='contact_department'
                        value={form.contact_department_id}
                        options={departmentOptions}
                        error={showError && form.contact_department_id === ''}
                        onChange={handleSelectChange('contact_department_id')}
                        inPopup
                    />
                </Col>
                <Col xs md={smallerScreenSize ? 6 : 3} padded
                    style={smallerScreenSize ? {paddingBottom: 0} : {}}
                >
                    <LabeledSelect
                        label='Address'
                        name='address'
                        value={form.default_address_id}
                        options={addressOptions}
                        onChange={handleSelectChange('default_address_id')}
                        inPopup
                    />
                </Col>
                <Col xs md={smallerScreenSize ? 12 : 6} padded
                    style={smallerScreenSize ? { position:'relative', marginBottom:'52px'} : {position:'relative'}}
                >
                    <Label>Tags</Label>
                    <AutosuggestTagsInput
                        value={map(form.contact_tags, (tag) => {
                            return find(tagOptions, { label: tag }) || { label: tag };
                        })}
                        tags={tagOptions}
                        onChange={(newTags)=>{
                            setInForm('contact_tags', map(newTags, 'label'));
                        }}
                    />
                </Col>

                <Col xs md={6} padded pt={20}>
                    <LabeledInput
                        label='Twitter'
                        name='contact_twitter'
                        value={form.contact_twitter}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded pt={20}>
                    <LabeledInput
                        label='Linkedin'
                        name='contact_linkedin'
                        value={form.contact_linkedin}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Facebook'
                        name='contact_facebook'
                        value={form.contact_facebook}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Skype'
                        name='contact_skype'
                        value={form.contact_skype}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledCheckbox
                        label="No Marketing"
                        checked={+form.contact_no_marketing === 1}
                        onChange={() => {
                            setInForm(
                                'contact_no_marketing',
                                +form.contact_no_marketing === 1 ? 0 : 1
                            );
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
});

