import { DisplayAccountStatus, isDisplayAccountStatus, validateDisplayAccountStatus } from "./account_status";
import { ClientPortal, isClientPortal } from "./client_portal";
import { DisplayContact, isDisplayContact } from "./contact";
import { Currency, isCurrency, validateCurrency } from "./currency";
import { DisplayIndustry, isDisplayIndustry } from "./industry";
import { DisplayTag, isDisplayTag, validateDisplayTag } from "./tag";
import { DisplayTax, isDisplayTax, validateDisplayTax } from "./tax";
import { DisplayTerms, isDisplayTerms, validateDisplayTerms } from "./terms";
import { isBool, isNumber, isString } from "./type_guards";
import { DisplayUser, isDisplayUser, validateDisplayUser } from "./user";
import { validateNonEmptyString } from "./validators";

export interface DisplayClient {
    id: string
    name: string
    industry: DisplayIndustry
    tenantAccountNumber: string | null
    clientRep: DisplayUser
    primaryContact: DisplayContact | null
    status: DisplayAccountStatus | null
    defaultTerms: DisplayTerms
    defaultTax: DisplayTax
    defaultCurrency: Currency
    tags: readonly DisplayTag[]
    minMargin: number | null
    portal: ClientPortal | null
    salesToDate: number
    salesTarget: number
    avalaraEntityUseCode: string | null
    creditHold: boolean
    parentClientId: string | null
    qboCustomerRef: number | null
    profile: string | null
}

// Type guards
export const isDisplayClient = (client: unknown): client is DisplayClient => {
    if (
        typeof client !== 'object' ||
        !('id' in client) ||
        !('name' in client) ||
        !('industry' in client) ||
        !('tenantAccountNumber' in client) ||
        !('status' in client) ||
        !('clientRep' in client) ||
        !('primaryContact' in client) ||
        !('defaultTerms' in client) ||
        !('defaultTax' in client) ||
        !('defaultCurrency' in client) ||
        !('tags' in client) ||
        !('minMargin' in client) ||
        !('portal' in client) ||
        !('salesToDate' in client) ||
        !('salesTarget' in client) ||
        !('avalaraEntityUseCode' in client) ||
        !('creditHold' in client) ||
        !('parentClientId' in client) ||
        !('qboCustomerRef' in client) ||
        !('profile' in client)
    ) {
        return false;
    }

    return (
        isString(client.id) &&
        isString(client.name) &&
        isDisplayIndustry(client.industry) &&
        (client.tenantAccountNumber === null || isString(client.tenantAccountNumber)) &&
        isDisplayUser(client.clientRep) &&
        (client.primaryContact === null || isDisplayContact(client.primaryContact)) &&
        (client.status === null || isDisplayAccountStatus(client.status)) &&
        isDisplayTerms(client.defaultTerms) &&
        isDisplayTax(client.defaultTax) &&
        isCurrency(client.defaultCurrency) &&
        (Array.isArray(client.tags) && client.tags.every(isDisplayTag)) &&
        (client.minMargin === null || isNumber(client.minMargin)) &&
        (client.portal === null || isClientPortal(client.portal)) &&
        isNumber(client.salesToDate) &&
        isNumber(client.salesTarget) &&
        (client.avalaraEntityUseCode === null || isString(client.avalaraEntityUseCode)) &&
        isBool(client.creditHold) &&
        (client.parentClientId === null || isString(client.parentClientId)) &&
        (client.qboCustomerRef === null || isNumber(client.qboCustomerRef)) &&
        (client.profile === null || isString(client.profile))
    );
}

// Validators
export const validateDisplayClient = (client: DisplayClient) => {
    if (client.salesToDate < 0) {
        throw new Error("Sales to date must be a non-negative number");
    }

    if (client.salesTarget < 0) {
        throw new Error("Sales target must be a non-negative number");
    }

    validateNonEmptyString(client.id);
    validateNonEmptyString(client.name);
    validateDisplayUser(client.clientRep);
    if (client.status) {
        validateDisplayAccountStatus(client.status);
    }
    validateDisplayTerms(client.defaultTerms);
    validateDisplayTax(client.defaultTax);
    validateCurrency(client.defaultCurrency);
    client.tags.forEach(validateDisplayTag);
}
