import React, { useMemo } from "react";
import styled from "styled-components";
import { SubPageHeader } from "../SubPageHeader";
import useClientDetails from "../hooks/useClientDetails";
import { ReportConfig, ReportElementSizes, getReportConfig } from "../../report/config";
import { EReportTypes } from "../../report/routes/report_types";
import { ReportProvider } from "../../report/report_context";
import { Csku } from "@commonsku/styles";
import { default as BaseReportFiltersContainer } from "../../report/filters/ReportFiltersContainer";
import ReportTable from "../../report/table/ReportTable";
import Pagination from "../../report/table/Pagination";
import Loading from "../../report/Loading";

const DEFAULT_STYLE_CONSTANTS: ReportElementSizes = {
    SIDE_BAR_WIDTH: 192,
    PREFERRED_WINDOW_WIDTH: 1512,
    FILTER_CONTAINER_HEIGHT: 0, // Filter is rendered elsewhere
    TABLE_ELEMENTS_GAP: 16,
    SUMMARY_CONTAINER_HEIGHT: 0, // No summary
    TABLE_PAGE_HEADER_HEIGHT: 152, // Header 72 + Subtitle 80
    ROW_HEIGHT: 80,
    TABLE_BOTTOM_MARGIN: 112,
} as const;

const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const ReportFiltersContainer = styled(BaseReportFiltersContainer)`
    justify-content: flex-end;
    > * {
        &:first-child {
            justify-content: flex-end;
        }
    }
`;

export const ProjectsPage = () => {
    const { client } = useClientDetails();

    const reportConfig: ReportConfig = useMemo(
        () => ({ ...getReportConfig(EReportTypes.ClientProject) }),
        []
    );

    const defaultFilters = useMemo(
        () => ({
            client_id: client.id,
        }),
        [client.id]
    );

    return (
        <ReportProvider
            reportConfig={reportConfig}
            defaultFilters={defaultFilters}
            elementSizeParameters={DEFAULT_STYLE_CONSTANTS}
        >
            <ProjectContainer>
                <SubPageHeader title="Projects">
                    <ReportFiltersContainer />
                </SubPageHeader>
                <Csku as={"div"}>
                    <ReportTable
                        headerClassName="client-project-report-table-header subreport-table-header"
                    />
                    <Pagination />
                    <Loading />
                </Csku>
            </ProjectContainer>
        </ReportProvider>
    );
};
