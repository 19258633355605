// Adapted from web/src/components/production-report/Tasks.js

import { Row, Col, LabeledSelect, Button } from "@commonsku/styles";
import _ from "lodash";
import React, { useState } from "react";
import DateInput, { CSKUInput } from "../../DateInput";
import MentionTextarea from "../../MentionTextarea";
import { DisplayNote, NoteType } from "../../../types";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../../utils";
import { Unvalidated } from "../types";
import { useIdentity } from "../../../hooks";

const styles = {
    label: {
        fontFamily: "'skufont-medium',sans-serif",
        color: '#123952',
        fontSize: '1rem',
        fontWeight: 400,
        width: '100%',
    },
    boxStyle: {
        borderBottom: '1px solid #E0E7EB',
        color: '#9A9A9A',
        padding: '10px',
        marginBottom: '16px',
        background: '#EDF2F4',
        borderRadius: '4px',
    }
};

export interface CreateNoteDialogProps {
    type: NoteType
    createNote: (newNote: Unvalidated<DisplayNote>) => void
}

export const CreateNoteDialog = ({
    type,
    createNote,
}: CreateNoteDialogProps ) => {
    const { user_id } = useIdentity();
    const [text, setText] = useState(null);
    const [userId, setUserId] = useState(null);
    const [reminderDate, setReminderDate] = useState(null);

    const users = Object.entries(useSelector((state: any) => state.entities.users))
        .map((user: any) => {
            return {
                label: user[1].user_first_name + ' ' + user[1].user_last_name,
                value: user[1].user_id,
            };
        });

    const mentionUsers = useSelector((state: any) => 
        Object.values(state.entities.users)
            .filter((user: any) => user.mask && user.mask !== '')
    );

    const handleCreateNote = () => {
        const newNote: Unvalidated<DisplayNote> = {
            type,
            parentType: 'MESSAGE',
            message: text,
            author: {
                id: user_id,
            },
            creationDate: new Date(),
            comments: [],
            pinned: false,
            reminder: (userId && reminderDate) ? {
                reminderUser: {
                    id: userId,
                },
                reminderDate: reminderDate,
            } : undefined,
        };

        createNote(newNote);
        setText('');
    };

    if (type === 'TASK' || type === 'CALL' || type === 'MEETING') {
        return (
            <Row>
                <Col padded xs>
                    <MentionTextarea placeholder={toTitleCase(type)}
                        value={text} mention_users={mentionUsers}
                        onChange={setText}
                        style={{ maxWidth: '100%' }}
                    />
                </Col>
                <Col padded xl={6} sm={6} xs>
                    <LabeledSelect label={`${toTitleCase(type)} For`} name="task_for" noMargin
                        value={_.find(users, { value: userId })} options={users} onChange={e => setUserId(e.value)}
                    />
                </Col>
                <Col padded xl={6} sm={6} xs>
                    <label style={styles.label}>{toTitleCase(type)} Date</label>
                    <DateInput
                        customInput={<CSKUInput />}
                        showMonthDropdown
                        showYearDropdown
                        placeholder={`${toTitleCase(type)} Date`}
                        value={reminderDate}
                        onChange={setReminderDate}
                    />
                </Col>
                <Col padded xl={6} sm={6} xs>
                    <Button onClick={handleCreateNote}>Create {toTitleCase(type)}</Button>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col padded xs>
                <MentionTextarea placeholder={toTitleCase(type)}
                    value={text || ''} mention_users={mentionUsers}
                    onChange={setText}
                    style={{ maxWidth: '100%' }}
                />
            </Col>
            <Col padded xs>
                <Button onClick={handleCreateNote}>{type === 'COMMENT' ? 'Add' : 'Create'} {toTitleCase(type)}</Button>
            </Col>
        </Row>
    );
}
