import { DisplayContact } from "../../types";

/**
 * Searches for a string in the first name, last name, and email of a contact.
 * 
 * TODO: fuzzy search
*/
export const searchContacts = (query: string, contacts: readonly DisplayContact[]) => {
    if (query === "") return contacts;

    const lowerCaseQuery = query.toLocaleLowerCase();

    return contacts.filter((contact) => {
        const fullName = `${contact.firstName} ${contact.lastName}`;
        return fullName.toLocaleLowerCase().includes(lowerCaseQuery) ||
            contact.email.toLocaleLowerCase().includes(lowerCaseQuery);
    });
}
